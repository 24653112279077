<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid"
        title="수질오염 방지시설 가동시간대"
        tableId="grid"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="grid.data"
        :usePaging="false"
        :hideBottom="false"
        :columnSetting="false"
        :filtering="false"
        :isExcelDown="false"
        :editable="editable"
        :gridHeightAuto="param.isFullScreen"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="저장" icon="save" v-if="!param.isFullScreen && editable" @btnClicked="saveData"/>
          </q-btn-group>
        </template>
      </c-table>
    </div>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'daily-report-02',
  props: {
    param: {
      type: Object,
      default: () => ({
        plantCd: null,
        envWaterDailyResultId: '',
        isFullScreen: false,
        dailyResultDt: '',
        approvalStatusCd: null,
        approvalTarget: false,
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'envWaterMstPreventiveName',
            field: 'envWaterMstPreventiveName',
            label: '수질오염 방지시설명',
            align: 'center',
            sortable: false,
          },
          {
            name: 'oprTime01',
            field: 'oprTime01',
            label: '1',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'oprTime02',
            field: 'oprTime02',
            label: '2',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'oprTime03',
            field: 'oprTime03',
            label: '3',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'oprTime04',
            field: 'oprTime04',
            label: '4',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'oprTime05',
            field: 'oprTime05',
            label: '5',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'oprTime06',
            field: 'oprTime06',
            label: '6',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'oprTime07',
            field: 'oprTime07',
            label: '7',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'oprTime08',
            field: 'oprTime08',
            label: '8',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'oprTime09',
            field: 'oprTime09',
            label: '9',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'oprTime10',
            field: 'oprTime10',
            label: '10',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'oprTime11',
            field: 'oprTime11',
            label: '11',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'oprTime12',
            field: 'oprTime12',
            label: '12',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'oprTime13',
            field: 'oprTime13',
            label: '13',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'oprTime14',
            field: 'oprTime14',
            label: '14',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'oprTime15',
            field: 'oprTime15',
            label: '15',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'oprTime16',
            field: 'oprTime16',
            label: '16',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'oprTime17',
            field: 'oprTime17',
            label: '17',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'oprTime18',
            field: 'oprTime18',
            label: '18',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'oprTime19',
            field: 'oprTime19',
            label: '19',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'oprTime20',
            field: 'oprTime20',
            label: '20',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'oprTime21',
            field: 'oprTime21',
            label: '21',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'oprTime22',
            field: 'oprTime22',
            label: '22',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'oprTime23',
            field: 'oprTime23',
            label: '23',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'oprTime24',
            field: 'oprTime24',
            label: '24',
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            setHeader: true,
            sortable: false,
          },
        ],
        height: '600px',
        data: [],
      },
      listUrl: '',
      saveUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  computed: {
    editable() {
      if (this.param.approvalTarget) {
        return true;
      } else if (this.param.approvalStatusCd !== null) {
        return false;
      } else {
        return this.$route.meta.editable
      }
    },
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.env.water.daily.preventive.list.url;
      this.saveUrl = transactionConfig.env.water.daily.preventive.save.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {envWaterDailyResultId: this.param.envWaterDailyResultId, plantCd: this.param.plantCd};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    saveData() {
      let saveData = this.grid.data.filter( x => {
        return x.editFlag == 'U' || x.editFlag == 'C'
      });
      this.$_.forEach(saveData, item => {
        item.regUserId = this.$store.getters.user.userId;
        item.chgUserId = this.$store.getters.user.userId;
      });
      if(saveData.length > 0) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.saveUrl;
            this.$http.type = 'POST';
            this.$http.param = saveData;
            this.$http.request(() => {
              this.getList();
              window.getApp.$emit('ALERT', {
                title: '안내', 
                message: '저장되었습니다.', 
                type: 'success', // success / info / warning / error
              });
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '변경된 데이터가 없습니다.',
          type: 'info', // success / info / warning / error
        });
      }
    },
  }
};
</script>
